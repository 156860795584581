import type { CSSProperties, HTMLAttributes } from 'react'
import { useMobile } from 'src/hooks/useMobile'

interface ArrowProps extends HTMLAttributes<HTMLSpanElement> {
  size: number
  thickness: number
  direction: DirectionType
  color?: string
}

type DirectionType = 'up' | 'right' | 'down' | 'left'

const handleDirection = (arrow: string): number => {
  switch (arrow) {
    case 'up':
      return -135

    case 'right':
      return -45

    case 'down':
      return 45

    case 'left':
      return 135

    default:
      return -45
  }
}

const ArrowIcon = ({
  size,
  thickness,
  direction,
  color = 'black',
  className,
}: ArrowProps) => {
  const { browser } = useMobile()

  const style: CSSProperties = {
    borderRight: `solid ${color}`,
    borderBottom: `solid ${color}`,
    borderWidth: `0 ${thickness}px ${thickness}px 0`,
    display: 'inline-block',
    padding: `${size}px`,
    transform: `rotate(${handleDirection(direction)}deg)`,
  }

  if (!browser.includes('Chrome')) {
    style.padding = `${size + 1}px`
    style.width = `${size + 1}px`
    style.height = `${size + 1}px`
  }

  return <span style={style} className={className} />
}

export default ArrowIcon
