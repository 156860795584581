import { Fragment, useState } from 'react'
import { windowSize } from 'src/utils/globalElements'

import ArrowIcon from './ArrowIcon'
import type { LocalProps, NationalProps } from './PartnerBenefitsDecathlon'

import './partnerStyles.scss'

const PartnersComponent = ({
  partners,
  className = '',
}: {
  partners: LocalProps[] | NationalProps[]
  className: string
}) => {
  const { screenWidth } = windowSize()
  const [isBtnOpen, setIsBtnOpen] = useState('')

  const handleButton = (key: string): void => {
    if (key === isBtnOpen) {
      setIsBtnOpen('')

      return
    }

    setIsBtnOpen(key)
  }

  const handleScreenWidth = (width: number): string => {
    return screenWidth <= width ? 'hidden' : 'block'
  }

  return (
    <section
      className={`partners-content bg-white max-h-[500px] w-full md:max-w-[400px] p-8 h-[500px] ${className}`}
    >
      {'city' in partners[0] ? (
        <div className="local-partners px-3 h-full  overflow-y-scroll">
          <h3
            className={`local-partners__title text-xl font-bold uppercase text-center pb-8 border-b border-black ${handleScreenWidth(
              768
            )}`}
          >
            locais
          </h3>
          <div className="local-partners__content flex flex-col">
            {(partners as LocalProps[]).map(
              (partner: LocalProps, idx: number) => {
                return (
                  <Fragment key={`local-partner-${idx}`}>
                    <button
                      className="local-city flex justify-between py-4 border-b border-black"
                      onClick={() => handleButton(`local-partner-${idx}`)}
                    >
                      <h4 className="text-base uppercase font-bold md:text-xl">
                        {partner?.city}
                      </h4>
                      <ArrowIcon
                        size={4}
                        thickness={2}
                        direction={
                          isBtnOpen === `local-partner-${idx}`
                            ? 'down'
                            : 'right'
                        }
                      />
                    </button>
                    <div
                      className={`local-companies ${
                        isBtnOpen === `local-partner-${idx}`
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      {partner?.entities.map((ent, idx2) => {
                        return (
                          <div
                            key={`local-company-${idx2}`}
                            className="local-company border-black border-b py-5 flex gap-5"
                          >
                            {ent?.logo && (
                              <div className="local-company__logo flex flex-col pp:w-[80%] max-w-[100px]">
                                <img
                                  src={ent?.logo}
                                  alt={`Parceiro ${ent?.name}`}
                                  className="w-full my-auto"
                                />
                              </div>
                            )}
                            <div className="local-company__info">
                              <h5 className="local-company__info--name pp:text-base font-bold pb-3">
                                {ent?.name}
                              </h5>
                              <div className="local-company__info--description">
                                {ent?.paragraphs.map((paragraph, idx3) => {
                                  return (
                                    <p
                                      key={`local-paragraph-${idx3}`}
                                      className="flex text-sm pb-3 items-center"
                                    >
                                      {paragraph}
                                      {ent?.link && (
                                        <a
                                          href={ent?.link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <img
                                            src="https://decathlonstore.vteximg.com.br/arquivos/button-club-image.png"
                                            alt="Botão flecha para redirecionamento"
                                            width={40}
                                          />
                                        </a>
                                      )}
                                    </p>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Fragment>
                )
              }
            )}
          </div>
        </div>
      ) : (
        <div className="national-partners bg-white h-full">
          <h3
            className={`national-partners__title text-xl font-bold uppercase text-center pb-8 border-b border-black ${handleScreenWidth(
              768
            )}`}
          >
            nacionais
          </h3>
          {(partners as NationalProps[]).map((partner, index) => {
            return (
              <div
                key={`national-company-${index}`}
                className="national-company border-black border-b py-5 flex gap-5"
              >
                <div className="national-company__logo">
                  {partner?.logo && (
                    <img
                      src={partner?.logo}
                      alt={`Parceiro ${partner?.name}`}
                      className="w-full"
                    />
                  )}
                </div>
                <div className="national-company__info">
                  <div className="national-company__info--name pp:text-xl font-bold pb-3">
                    {partner?.name}
                  </div>
                  <div className="national-company__info--description">
                    {partner?.paragraphs.map((paragraph, index2) => {
                      return (
                        <p
                          key={`national-paragraph-${index2}`}
                          className="flex pp:text-base pb-3 items-center"
                        >
                          {paragraph}{' '}
                          {partner?.link && (
                            <a
                              href={partner?.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="https://decathlonstore.vteximg.com.br/arquivos/button-club-image.png"
                                alt="Botão flecha para redirecionamento"
                                width={40}
                              />
                            </a>
                          )}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}

export default PartnersComponent
