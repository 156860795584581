import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import type { ClubeDecathlonPartnersQuery } from '@generated/graphql'

import PartnersComponent from './PartnersComponent'

export type NationalProps = {
  name: string
  logo?: string
  link?: string
  paragraphs: string[]
}

export type LocalProps = {
  city: string
  entities: NationalProps[]
}

export type PartnerType = 'national' | 'local'

const PartnerBenefitsDecathlon = () => {
  const {
    allCmsClubeDecathlon: {
      edges: [
        {
          node: {
            sections: [
              {
                data: { partners },
              },
            ],
          },
        },
      ],
    },
  } = useStaticQuery<ClubeDecathlonPartnersQuery>(database)

  const { national, local } = partners

  const [partnerType, setPartnerType] = useState<PartnerType>('national')
  const { isMobile } = useMobile()

  const bgImage =
    'https://decathlonpro.vteximg.com.br/arquivos/ids/2601719/beneficios_parceiros--home_club_interna_beneficios.jpg?v=637437269619270000'

  const backStyle = {
    backgroundImage: `url(${bgImage})`,
    boxShadow: 'inset 0 0 0 100vw rgba(0, 0, 0, 0.4)',
    backgroundSize: 'cover',
    backgroundPosition: `${
      typeof window !== 'undefined' && window.screen.width < 1280
        ? '81%'
        : '60%'
    }`,
  }

  const handlePartnerType = (key: PartnerType) => {
    setPartnerType(key)
  }

  const showPartnersSection = (key: string, pattern: string): string => {
    if (isMobile) {
      return key === pattern ? 'block' : 'hidden'
    }

    return ''
  }

  return (
    <section
      className="main-partner-benefits relative max-h-full bg-no-repeat"
      style={backStyle}
    >
      <div className="partner-benefits-info pp:py-[5%] pp:px-[10%] flex flex-col items-center">
        <div className="partner-benefits-info__logoDecathlon flex justify-center mb-3">
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2591582/clube-decathlon-768x360.png?v=637394270635900000"
            alt="Logo Clube Decathlon"
            className="pp:w-[228px]"
          />
        </div>
        <h1 className="partner-benefits-info__mainTitle text-white uppercase font-bold text-center pp:text-lg md:text-xl pp:tracking-[6px] my-3">
          mais benefícios
          <br />
          mais esporte
        </h1>
        <div className="partner-benefits-info__serviceLogo bg-gradient-to-t from-[#296ffa] via-[#0073b3] to-[#451acf] mt-10 rounded-full w-[110px] h-[110px] flex flex-col items-center justify-center">
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2601720/section_01_main_icon--beneficios_parceiros_icon.png?v=637437279370730000"
            alt="Benefícios em parceiros Decathlon"
            className="w-14 h-14"
          />
        </div>
        <h2 className="partner-benefits-info__secondTitle uppercase text-white text-center text-3xl font-bold my-6">
          benefícios em parceiros decathlon
        </h2>
        <p className="partner-benefits-info__description text-xl text-white leading-[1.14] text-center my-5 px-1">
          Cliente Clube Decathlon tem acesso a benefícios e descontos em nossos
          parceiros.
        </p>
      </div>
      <div
        className={`partners-buttons w-full flex gap-[2px] pp:px-3 sm:px-10 ${
          isMobile ? 'block' : 'hidden'
        }`}
      >
        <button
          onClick={() => handlePartnerType('national')}
          className={`partners-button-national p-[30px] font-bold text-xl uppercase w-[50%] ${
            partnerType === 'national'
              ? 'bg-white text-black'
              : 'bg-whiteRGBA text-white'
          }`}
        >
          nacionais
        </button>
        <button
          onClick={() => handlePartnerType('local')}
          className={`partners-button-national pp:p-[30px] font-bold text-xl uppercase w-[50%] ${
            partnerType === 'local'
              ? 'bg-white text-black'
              : 'bg-whiteRGBA text-white'
          }`}
        >
          locais
        </button>
      </div>
      <div className="partner-benefits-companies flex pp:w-full pp:px-3 pp:pb-6 pp:pt-2 pp:gap-4 sm:px-10 md:gap-[70px] md:justify-center md:px-20">
        <PartnersComponent
          className={showPartnersSection(partnerType, 'national')}
          partners={national}
        />
        <PartnersComponent
          className={showPartnersSection(partnerType, 'local')}
          partners={local}
        />
      </div>
    </section>
  )
}

export const database = graphql`
  query ClubeDecathlonPartners {
    allCmsClubeDecathlon(
      filter: { id: { eq: "a9f84a17-4cba-53cc-bb4c-5e8389d2b128" } }
    ) {
      edges {
        node {
          id
          sections {
            name
            data
          }
        }
      }
    }
  }
`

export default PartnerBenefitsDecathlon
